<template>
  <div class="card p-1">
    <div>
      <h3 class="font-weight-bolder font-medium-3 m-0">
        ประวัติการเข้าใช้งาน
      </h3>
    </div>

    <div class="row">
      <div class="col-md-3">
        <b-form-group>
          <label for="search">ค้นหาประเภทสมาชิก</label>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="option"
          />
        </b-form-group>
      </div>
      <div class="col-md-3">
        <b-form-group>
          <label for="search">ค้นหาขื่อผู้ใช้</label>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="option"
          />
        </b-form-group>
      </div>
      <div class="col-md-6" />
    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(picture)="data">
        <b-avatar
          size="lg"
          :src="data.item.picture"
        />
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <a
            :href="`http://${data.item.ip}`"
            target="_blank"
          >{{ data.item.ip }}</a>

        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editData(data.item)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
      <template #cell(match)="data">
        <div class="text-nowrap">
          <span v-if="data.item.match === 1">ยืนยันแล้ว</span>
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BCardBody, BPagination, BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BTable,
    BCardBody,
    BPagination,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      selected: null,
      option: [
        { title: 'ทั้งหมด', value: 'all' },
        { title: 'สมาชิกทั่วไป', value: 'member' },
        { title: 'สมาชิกพิเศษ', value: 'vip' },
      ],
      fields: [
        { key: 'index', label: 'no.', thStyle: { width: '5%' } },
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
          thWidth: '20%',
        },
        { key: 'username', label: 'ชื่อเข้าใช้งาน' },
        { key: 'name', label: 'ชื่อ-สกุล' },
        { key: 'ip_address', label: 'IP Address', thStyle: { width: '50%' } },
      ],
    }
  },

}
</script>

<style>

</style>
